.ofertamodule__kontener {
  width: 32%;
  min-height: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.ofertamodule__img-section {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.ofertamodule__img-section__overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--overlay);
  opacity: 0.3;
}

.ofertamodule__title-section {
  z-index: 2;
  padding: 1rem;
}

.ofertamodule__title-section h3 {
  color: var(--secondTextFontColor);
  font-size: clamp(1.125rem, 0.67vw + 0.696rem, 1.5rem);
}

.ofertamodule__opis {
}

.ofertamodule__opis p {
  color: var(--secondTextFontColor);
  padding: 2rem 1rem;
  text-align: justify;
  font-size: clamp(0.875rem, 0.335vw + 0.66rem, 1.063rem);
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .ofertamodule__kontener {
    width: 49%;
  }

  .ofertamodule__title-section h3 {
    font-size: 22px;
  }

  .ofertamodule__opis p {
    font-size: clamp(0.875rem, 0.502vw + 0.741rem, 1.063rem);
  }
}

@media screen and (max-width: 425px) {
  .ofertamodule__kontener {
    width: 100%;
  }
  .ofertamodule__title-section h3 {
    font-size: clamp(1.125rem, 3.81vw + 0.363rem, 1.375rem);
  }

  .ofertamodule__opis p {
    font-size: clamp(0.875rem, 2.857vw + 0.304rem, 1.063rem);
  }
}
