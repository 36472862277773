.formik__wrapper {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.inputs_container {
  width: 100%;
  gap: 4%;
  display: flex;
  flex-wrap: wrap;
}

.formik_field-container {
  padding: 2rem 0;
  width: 48%;
  position: relative;
}

.formik__field {
  min-height: 60px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--accent);
  color: var(--placeholder);
  outline: none;
  padding-left: 1rem;
  font-size: 18px;
}

.formik__field::placeholder {
  font-size: 18px;
}

.formik__field:focus,
.formik__field:focus-visible,
.formik__field:focus-within {
  background: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--firstBackground) inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--placeholder);
  color: var(--placeholder);
}

.formik__field::placeholder,
.errorMessage__label {
  color: var(--placeholder);
}

.errorMessage__label,
.textarea__container .errorMessage__label {
  bottom: -1rem;
  left: 0;
  position: absolute;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.textarea__container .errorMessage__label {
  bottom: -2rem;
}

.textarea__container {
  display: flex;
  justify-content: center;
}

.formik__question {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formik_question-zawartosc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.formik_question-zawartosc .formik__field {
  width: 45%;
  text-align: center;
  padding: 0;
}

.formik_pytanie__kontener {
  margin-bottom: 1rem;
}

.formik_pytanie__kontener p {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary);
}

.formik_pytanie__kontener p span {
  color: var(--accent);
}

.formiButton__container {
  margin-top: 2rem;
}

.formiButton__container button {
  display: flex;
  padding: 1.5rem 4rem;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  border: none;
  background-color: var(--secondary);
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: var(--secondTextFontColor);
  transition: var(--mainTransition);
}

.formiButton__container button:hover {
  background-color: var(--primary);
}

.formik__messeageAfterSubmitTrue,
.formik__question-error,
.formik__messeageAfterSubmitFals {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 4px solid;
  color: var(--secondTextFontColor);
}

.formik__messeageAfterSubmitFalse p {
  color: var(--secondTextFontColor);
}

.formik__messeageAfterSubmitTrue {
  background-color: #7dce13;
  border-color: green;
}

.formik__question-error,
.formik__messeageAfterSubmitFalse {
  background-color: #cd1818;
  border-color: red;
}

@media screen and (max-width: 1024px) {
  .formik_field-container {
    width: 100%;
  }

  .formik_question-zawartosc {
    width: 100%;
  }

  .formik_question-zawartosc .formik__field {
    width: 50%;
  }
  .formiButton__container {
    width: 100%;
  }

  .formiButton__container button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
