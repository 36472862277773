.header__kontener {
  min-height: calc(100vh - var(--nawigacja__panel-gornyHEIGHT));
  position: relative;
}

.header__top-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: space-between;
}

.header__logo {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.header__logo img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.header__logo p {
  margin-top: 0.5rem;
  color: var(--secondTextFontColor);
}

.header__logo p span {
  font-weight: bold;
}

.header__kontener .menu__kontenerDestkop {
}

@media screen and (max-width: 1024px) {
  .header__kontener .menu__kontenerDestkop {
    display: none;
  }
}
