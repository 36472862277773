.menu__kontenerDestkop {
  z-index: 2;
  padding-top: 3rem;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  height: var(--menuDesktopHEIGHT);
}

.menu__kontenerDestkop a {
  position: relative;

  padding-bottom: 1.2rem;
  text-decoration: none;
  font-weight: 500;
  color: var(--secondTextFontColor);
  letter-spacing: 0.5px;
  font-size: clamp(1rem, 0.223vw + 0.857rem, 1.125rem);
  transition: var(--mainTransition);
}
.menu__kontenerDestkop a span {
  position: absolute;
  bottom: 0;
  display: block;
  height: 2px;
  width: 0%;
  transition: var(--mainTransition);
  background-color: var(--primary);
}

.menu__kontenerDestkop a:hover {
  color: var(--primary);
}

.menu__kontenerDestkop a:hover span {
  width: 100%;
}
