.about__kontener {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about__text-kontener,
.about__img-kontener {
  width: 50%;
}

.about__text-kontener {
  padding-right: 3rem;
}

.title__h2 {
}


.about__text-section {
  width: 100%;
  margin-top: 2rem;
}

.about__text-section p {
  font-size: clamp(1rem, 0.224vw + 0.857rem, 1.125rem);
  line-height: 150%;
  text-align: justify;
}

.about__img-kontener img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .about__kontener {
    flex-direction: column;
  }
  .about__text-kontener,
  .about__img-kontener {
    width: 100%;
  }

  .about__text-kontener {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .about__text-section p {
    font-size: clamp(0.938rem, 0.426vw + 0.852rem, 1.125rem);
  }
}
