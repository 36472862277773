.sliderV1__kontener {
  position: relative;
  width: 100%;
  height: calc(100vh - var(--nawigacja__panel-gornyHEIGHT));
  display: flex;
  align-items: flex-end;
}

.Slide_img-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.45;
  background: linear-gradient(
    90deg,
    var(--overlay) 0,
    /* rgba(25, 48, 87, 0) 30%, */ var(--placeholder) 50%,
    var(--overlay) 100%
  );
}

.Slide_img-blink {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
}

.Slide_img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.Slide_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sliderV1__pozostalaZawartosc-kontener {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  width: 100%;
  height: calc(
    100vh -
      (
        var(--nawigacja__panel-gornyHEIGHT) +
          var(--nawigacja__panel-gornyHEIGHT) + 1.5rem
      )
  );
}

.sliderV1__title {
  width: 100%;
  max-width: 100%;
}

.sliderV1__title h1 {
  width: 100%;
  color: var(--secondTextFontColor);
  font-size: 62px;
  padding: 1rem 0;
}

.sliderV1__pozostalaZawartosc-paragraph {
  margin-top: 2rem;
}

.sliderV1__pozostalaZawartosc-paragraph p {
  font-size: clamp(1.125rem, 0.67vw + 0.696rem, 1.5rem);
  color: var(--secondTextFontColor);
}

.SliderV1__titleSlide {
  /* animation: SliderV1__titleSlide 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
  animation: SliderV1__titleSlide 0.7s ease-in-out both;
  animation-delay: 0.5s;
}

@keyframes SliderV1__titleSlide {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
    opacity: 100;
  }
}

.SliderV1__paragraphSlide {
  animation: SliderV1__paragraphSlide 0.5s ease-in-out both;
  animation-delay: 0.7s;
}

@keyframes SliderV1__paragraphSlide {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 100%;
    transform: translateX(0);
  }
}

@media screen and (max-width: 1024px) {
  .sliderV1__title h1 {
    word-break: break-word;
    font-size: clamp(2.5rem, 3.125vw + 1.875rem, 3.875rem);
    width: 100%;
  }
}
