.oferta__kontener {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
}

.oferta__zawartosc .title__h2 h2,
.oferta__zawartosc .subtitle__h4 h4 {
  color: var(--secondTextFontColor);
}

.oferta__kontener-zawartosci {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

@media screen and (max-width: 1024px) {
}
