.footer__kontener {
  width: 100%;
  height: 100%;
  background-color: var(--footer);
  color: var(--secondTextFontColor);
}

.footer__kontener-zawartosc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.footer__logo img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.footer__logo p {
  font-size: 24px;
  margin-top: 1rem;
}

.footer__logo,
.footer__call,
.socialMediaIcons__mobile {
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--placeholder);
  padding: 2rem 0;
}

.footer__call {
  width: 100%;

  border-bottom: 1px solid var(--placeholder);
}

.footer__call p:first-of-type {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: clamp(1.25rem, 0.223vw + 1.107rem, 1.375rem);
}

.footer__call p {
  text-align: center;
}

.footer__call p a {
  color: var(--secondary);
  font-weight: bold;
  font-size: clamp(2.625rem, 1.117vw + 1.909rem, 3.25rem);
}
.socialMediaIcons__mobile {
  display: none;
}

.footer__adres {
  margin-top: 1rem;
}

.footer__adres p {
  margin-bottom: 1rem;
  font-size: 18px;
  text-align: center;
}

.footer__copyright p,
.footer__realizacja p,
.footer__realizacja p a {
  color: var(--placeholder);
}

.footer__realizacja {
  margin-top: 1rem;
}

.footer__realizacja p {
  font-size: 16px;
}

.footer__realizacja p a {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .footer__call p:first-of-type {
    font-size: clamp(1.125rem, 1.136vw + 0.898rem, 1.625rem);
  }

  .socialMediaIcons__mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  .socialMediaIcons__mobile a {
    color: var(--secondTextFontColor);
    font-size: 32px;
    transition: var(--mainTransition);
  }

  .socialMediaIcons__mobile a:hover {
    color: var(--secondary);
  }

  .footer__call p a {
    font-size: clamp(1.75rem, 1.989vw + 1.352rem, 2.625rem);
  }

  .footer__adres p {
    font-size: clamp(1rem, 0.284vw + 0.943rem, 1.125rem);
  }
  .footer__copyright p,
  .footer__realizacja p {
    font-size: clamp(0.688rem, 0.71vw + 0.545rem, 1rem);
  }
}
