@media screen and (max-width: 1024px) {
  .menuMobilne__kontener {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .menuMobilne__kontener p {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    font-weight: bold;
    border-bottom: 2px solid var(--primary);
    transition: var(--mainTransition);
  }
  .menuMobilne__kontener p:last-of-type {
    border: none;
  }
  .menuMobilne__kontener p a {
    color: var(--primary);
  }

  .menuMobilne__kontener p:hover {
    background-color: var(--primary);
  }
  .menuMobilne__kontener p:hover a {
    color: var(--secondTextFontColor);
  }
}
