@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  /* CZCIONKA */
  --mainFont: "Roboto";

  /* KOLORY */
  /* TEXT */
  --firstTextFontColor: #000;
  --secondTextFontColor: #ffffff;
  /* TŁO */
  --firstBackground: #ffffff;
  /* KOLORY */
  --primary: #193057;
  --secondary: #056cf2;
  --accent: #5898bb;
  --overlay: #000;
  --placeholder: #61677a;
  --footer: #1b2125;

  /* WYMIARY */
  --nawigacja__panel-gornyHEIGHT: 60px;
  --menuDesktopHEIGHT: 85px;

  /* TRANSITION */
  --mainTransition: 0.7s;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--mainFont), sans-serif;
  text-decoration: none;
}

body {
  background-color: var(--firstBackground);
}

/* PADDING */
.sekcja-padding {
  padding: 6rem 12rem;
}

@media screen and (max-width: 1440px) {
  .sekcja-padding {
    padding: 4rem 8rem;
  }
}

@media screen and (max-width: 1200px) {
  .sekcja-padding {
    padding: 4rem 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .sekcja-padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .sekcja-padding {
    padding: 3rem;
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  .sekcja-padding {
    padding: 2rem;
  }
}

/* PADDING NAWIGACJA */
.sekcja-padding_nawigacja {
  padding: 0 12rem;
}

@media screen and (max-width: 1440px) {
  .sekcja-padding_nawigacja {
    padding: 0 8rem;
  }
}

@media screen and (max-width: 1200px) {
  .sekcja-padding_nawigacja {
    padding: 0 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .sekcja-padding_nawigacja {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 768px) {
  .sekcja-padding_nawigacja {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  .sekcja-padding_nawigacja {
    padding: 0rem 2rem;
  }
}

/* TYTUŁY */
.title__h2 h2 {
  text-transform: capitalize;
  font-size: 42px;
}

.title__h2 span {
  margin-top: 0.5rem;
  display: block;
  height: 3px;
  width: 50px;
  background-color: var(--accent);
}

@media screen and (max-width: 1024px) {
  .title__h2 h2 {
    font-size: clamp(2rem, 1.42vw + 1.716rem, 2.625rem);
  }
}

.subtitle__h4 {
  margin-top: 1rem;
}

.subtitle__h4 h4 {
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: clamp(1.125rem, 0.447vw + 0.839rem, 1.375rem);
}

@media screen and (max-width: 1024px) {
  .subtitle__h4 h4 {
    font-size: clamp(1rem, 0.852vw + 0.83rem, 1.375rem);
  }
}
