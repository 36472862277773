.politykaprywatnosci__kontener {
  /* overflow: hidden; */
  z-index: 11;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--firstBackground);
}

.polityka_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
}
.polityka_title_h3-section h3 {
  font-size: clamp(1.125rem, 0.671vw + 0.695rem, 1.5rem);
}

.polityka_title_button-section button {
  cursor: pointer;
  border: none;
  background: none;
  font-size: clamp(1.625rem, 1vw + 1.425rem, 2.625rem);
  transition: var(--mainTransition);
}

.polityka_title_button-section button:hover {
  scale: 110%;
}

.politykaprywatnosci__tresc-kontener {
  padding: 2rem 1rem 2rem 0;
  height: 100%;
  overflow-y: scroll;
}

.politykaprywatnosci__tresc-kontener p {
  font-size: clamp(0.875rem, 0.25vw + 0.825rem, 1.125rem);
  margin-bottom: 1rem;
}

.politykaprywatnosci__tresc-kontener h5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: clamp(1.125rem, 0.5vw + 1.025rem, 1.625rem);
}
