.nawigacja__panel-gorny {
  background-color: var(--primary);
  color: var(--secondTextFontColor);
  height: var(--nawigacja__panel-gornyHEIGHT);
  display: flex;
}

.nawigacja__panel-gorny_zawartosc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nawigacja__panel-gorny-socialMedia {
}

.nawigacja__panel-gorny-socialMedia-icon_kontener {
  width: 100%;
  display: flex;

  align-items: center;
  gap: clamp(1.125rem, 1.564vw + 0.123rem, 2rem);
}

.nawigacja__panel-gorny-socialMedia-icon_kontener a {
  color: var(--fistTextColor);
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--mainTransition);
}

.nawigacja__panel-gorny-socialMedia-icon_kontener a:hover {
  color: var(--secondary);
}

.nawigacja__panel-gorny-socialMedia-icon_kontener a:nth-of-type(2) {
  font-size: 24px;
}
.nawigacja__panel-gorny-socialMedia-icon_kontener a:nth-of-type(3) {
  font-size: 26px;
}

.nawigacja__panel-gorny-kontakt {
  display: flex;
  align-items: center;
}

.nawigacja__panel-gorny-kontakt p {
  font-size: clamp(1.063rem, 0.335vw + 0.848rem, 1.25rem);

  font-weight: 500;
  display: flex;
  align-items: center;
}

.nawigacja__panel-gorny-kontakt p a {
  color: var(--secondTextFontColor);
}

.nawigacja__panel-gorny-hamburgerIcon {
  display: none;
}

.nawigacja__panel-gorny-kontakt p span {
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.menuMobilne__kontener {
  display: none;
}

@media screen and (max-width: 1024px) {
  .menuMobilne__kontener {
    display: block;
  }

  .nawigacja__panel-gorny-socialMedia-icon_kontener {
    display: none;
  }
  .nawigacja__panel-gorny-hamburgerIcon {
    display: block;
  }
  .nawigacja__panel-gorny-hamburgerIcon button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    font-size: clamp(2.25rem, 1.42vw + 1.966rem, 2.875rem);
    color: var(--secondTextFontColor);
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
}
