.cookiebanner__kontener {
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  background-color: var(--secondary);
  z-index: 10;
  padding: 2rem;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.cookieBanner-paragraph__section {
  color: var(--secondTextFontColor);
}

.cookieBanner-paragraph__section p {
  font-size: clamp(0.875rem, 0.25vw + 0.825rem, 1.125rem);
}

.cookieBanner-paragraph__section p a {
  cursor: pointer;
  text-decoration: underline;
  color: var(--accent);
}

.cookieBanner-button__section button {
  cursor: pointer;
  padding: 1rem 2rem;
  border: none;
  transition: var(--mainTransition);
  background-color: var(--accent);
  text-transform: uppercase;
  font-weight: 500;
  color: var(--secondTextFontColor);
  font-size: 16px;
  letter-spacing: 0.5px;
}

.cookieBanner-button__section button:hover {
  background-color: var(--primary);
}

@media screen and (max-width: 425px) {
  .cookiebanner__kontener {
    gap: 1rem;
    flex-direction: column;
  }
}
